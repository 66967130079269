<template>
	<div
			class="d-flex fill-height" style="justify-content: center; align-items: center;"
			:style="{fontSize: Math.max(this.height, this.width) * 0.30 + 'px', color: randomColor(), lineHeight: Math.max(this.height, this.width) * 0.25 + 'px'}"
	>
		{{ parseInt(widgetData.items[0].values[1]) }}
	</div>
</template>

<script>
	export default {
		name: "Data",
		props: ['widgetData', 'width', 'height'],
		methods: {
			randomColor(){
				var letters = '456789ABCDEF';
				var color = '#';

				for (var i = 0; i < 6; i++) {
					color += letters[Math.floor(Math.random() * 12)];
				}

				return color;
			},
		}
	}
</script>

<style scoped>

</style>